import {withPrefix} from 'gatsby';
import React from 'react';

import {Button} from '../../../components/button';
import {Layout} from '../../../components/layout';

const locale = 'en';

export default () => (
    <Layout
        title="Security & Vulnerability"
        description="Found a vulnerability in one of our software products?"
        ogImage="security-en.png"
        translatedPagePath="/nl/voorwaarden/veiligheid/"
        locale={locale}>
        <section className="section">
            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            Security <br />& Vulnerability
                        </h2>
                        <div className="display__number">SV</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            At Label305 we believe the safety of the software products we develop, as well as our own
                            systems is of the utmost importance. But no matter how much effort we put into system
                            security, there can still be vulnerabilities present.
                        </p>
                        <p>
                            If you have found a vulnerability in one of our own systems, please let us know so that we
                            can take action <span className="highlight">as soon as possible</span>.
                        </p>
                        <p>
                            Have you found a vulnerability in one of the software products we develop for a client? Also
                            then we would like to hear from you, so that we can get to work quickly. Unfortunately we
                            cannot always speak for our client. Therefore, first check the responsible disclosure policy
                            of the software product itself.
                        </p>
                        <p>
                            We strive to solve all problems as quickly as possible and we are happy to be involved with
                            a possible publication about the problem after it has been solved.
                        </p>
                        <p>
                            <u>
                                Due to the many reports of trivial or unexploitable vulnerabilities and accepted risks
                                over the past few years, we <span className="highlight">no longer</span> provide
                                rewards.
                            </u>
                        </p>
                    </div>

                    <div className="content__buttons content__buttons--50">
                        <Button
                            url="mailto:security@305.nl"
                            name="security@305.nl"
                            title="Email us with a security alert"
                        />
                    </div>
                </div>
            </article>
        </section>
    </Layout>
);
